export const ARTICLE_CARD_TEST_IDS = {
  BY_LINE: 'article-card-by-line',
  DATE: 'article-card-date',
  EXCERPT: 'article-card-excerpt',
  EYEBROW: 'article-eyebrow',
  HERO_WRAPPER: 'hero-article-card-wrapper',
  IMAGE: 'article-card-image',
  IMAGE_LINK: 'article-image-link',
  TITLE: 'article-card-title',
  WRAPPER: 'article-card-wrapper',
};

export const ARTICLE_SECTION_TEST_IDS = {
  ARTICLES_LIST: 'articles-list',
  HERO_ARTICLE: 'hero-article',
  LATEST_ARTICLES: 'latest-articles',
  LATEST_ARTICLES_SM: 'latest-articles-sm',
  MORE_ARTICLES: 'more-articles',
  PAGE_INFO: 'page-info',
  PINNED_LINKS: 'article-section-pinned-links',
  SECTION_PREVIEW: 'section-preview',
  TITLE: 'title',
  TOP_ARTICLES: 'top-articles',
  TOP_PICKS: 'top-picks',
};
