/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { Theme, getColor } from './theme';

export const imageAndTitleLinkHover = css<{ theme: Theme }>`
  &:has(.image-link:hover) {
    .title-link {
      color: ${getColor('textLink')};
    }
  }

  &:has(.title-link:hover) {
    .image-link {
      filter: brightness(80%);
    }
    .excerpt-link {
      color: ${getColor('textLink')};
    }
  }
`;
